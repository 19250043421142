function findValueOfNestedObject(keyString, obj) {
  if (!obj) return null
  if (keyString.includes('.')) {
    const [key, ...rest] = keyString.split('.')
    if (rest.length) return findValueOfNestedObject(rest.join('.'), obj[key])
    else return obj[key]
  }
  return obj[keyString]
}

export function couponConditionJudgment(coupon, useStore) {
  const { coupon_condition } = coupon
  const invalidCondition = coupon_condition.map(({ property, operator, value, msg }) => {
    const [store, ...key] = property.split('.')
    const targetColumnValue = findValueOfNestedObject(key.join('.'), useStore.get(store))
    // console.log(coupon.coupon_name, targetColumnValue, property, operator);
    switch (operator) {
      case '==': return targetColumnValue === value ? null : msg
      case '!=': return targetColumnValue !== value ? null : msg
      case '>': return targetColumnValue > value ? null : msg
      case '<': return targetColumnValue < value ? null : msg
      case '>=': return targetColumnValue >= value ? null : msg
      case '<=': return targetColumnValue <= value ? null : msg
      case 'in': return targetColumnValue.includes(value) ? null : msg
      case 'exist': return targetColumnValue ? null : msg
      case 'haveLength': return targetColumnValue && targetColumnValue.length ? null : msg
      case 'includes': return targetColumnValue.find(e => {
        if (typeof value === 'object') return Object.keys(value).every(key => e[key] === value[key])
        else return e === value
      }) ? null : msg
      case 'notIncludes': return targetColumnValue.find(e => {
        if (typeof value === 'object') return Object.keys(value).every(key => e[key] === value[key])
        else return e === value
      }) ? msg : null
      case 'notOnlyIncludes': return targetColumnValue.find(e => {
        if (typeof value === 'object') return Object.keys(value).every(key => e[key] === value[key])
        else return e === value
      }) && targetColumnValue.length === 1 ? msg : null
    }
  }).filter(e => e)
  console.log(invalidCondition);
  return invalidCondition.length ? invalidCondition : null
}
