<script setup>
import { onMounted, ref, watch } from "vue";
import { useCommentStore } from "../../store/comment";
import { useProductStore } from "../../store/product";
import Icon from "@/components/Icon"
import { useRoute, useRouter } from "vue-router";

const ProductStore = useProductStore()
const CommentStore = useCommentStore()
const Router = useRouter()
const Route = useRoute()
const focusProduct = ref(null)
onMounted(() => {
  window.scrollTo(0, 0)
  if (!CommentStore.commentList.length) CommentStore.queryComment()
})
watch(ProductStore, () => {
  if (Route.params.productName && ProductStore.productList.length && !focusProduct.value) {
    const index = ProductStore.productList.map(p => p.product_name_en.replace(/\s/g, '-').toLowerCase()).indexOf(Route.params.productName)
    focusProduct.value = ProductStore.productList[index]
  }
}, { immediate: true, deep: true })
</script>

<template>
  <div class="view-comments product" v-if="focusProduct">
    <header>
      <div>
        <span @click="() => { Router.push('/comments') }"><icon icon="arrow-left"/></span>
        <h1>{{ focusProduct.product_name_zh }} REVIEW</h1>
      </div>
    </header>

    <main>
      <ul class="product-list comment-list">
        <li v-for="comment in CommentStore.filterByProduct[focusProduct.product_id].filter(c => c.comment_content)" :key="comment.comment_id">
          <router-link :to="`/comments/post/${comment.comment_id}`">
            <div class="img-wrapper">
              <img :src="comment.comment_images ? `/img/_comment/${comment.comment_images[0]}` : `/img/product/${focusProduct.product_uid}/avatar.webp`" :alt="comment.user_name">
              <span v-if="comment.comment_source === 'GLOBAL'">全球評價</span>
            </div>
            <div class="content">
              <h2>{{ comment.comment_title }}</h2>
              <p>{{ comment.comment_content }}</p>
              <div class="score">
                <div class="stars">
                  <span class="base"><Icon icon="star-fill" v-for="i in 5" :key="`star-${i}`" /></span>
                  <span
                    class="fill"
                    :style="{
                      width: `${comment.comment_stars/5*100}%`
                    }"
                  >
                    <Icon icon="star-fill" v-for="i in 5" :key="`star-${i}`" />
                  </span>
                </div>
              </div>

              <button>查看心得</button>
            </div>
          </router-link>
        </li>
      </ul>
    </main>
  </div>
</template>
